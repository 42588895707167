import React from 'react';
import {Link as GatsbyLink} from 'gatsby';
import Link from '../../../Shared/Link/Link';
import tintashLogoSrc from '../../../../assets/images/connect/web3/tintash-logo.svg';
import headingImage from '../../../../assets/images/connect/web3/heading-image-1.svg';

function HeadingSection() {
  return (
    <section className="live-ops__main heading-section bg-web3">
      <div className="heading-container">
        <div className="heading-subsection">
          <div className="heading-title-white">Experience of Working with 6 Web 3.0 Unicorns</div>
          <p className="heading-description-white mb-lg-4">
            The Tintash Web3.0 studio has been around since 2021. We’ve helped six Web3.0 unicorns
            surpass their annual product roadmaps with solutions like Asset Enablement, Wallet
            Development, dApp Development, Layer Zero Bridging, and Stable Coins. <br />
            <br /> When companies have a roadmap to deliver on in a limited budget, we’re the best
            game in town.
          </p>
        </div>
        <div className="heading-image-container">
          <img className="heading-image" src={headingImage} alt="heading" />
        </div>
      </div>
    </section>
  );
}

export default HeadingSection;
