import React from 'react';
import {Link} from 'gatsby';
import {Event} from '../../../GAClickEvent';

// selected Case Studies
const selectedCaseStudies = [
  {
    id: '1',
    featuredImageSrc: require('../../../../assets/images/web3/card1.webp').default,
    title: 'Leading US cryptocurrency exchange chose Tintash to accelerate their roadmap ',
    furtherdetails: [
      {
        id: '1',
        title: '4x',
        content: 'Faster launch times for 90% new blockchains',
      },
      {
        id: '2',
        title: '4 weeks',
        content: 'To deliver a complete Rosetta implementation',
      },
    ],
    caseStudyUrl: '/portfolio/cryptocurrency-exchange-platform',
    projectName: '',
  },
  {
    id: '2',
    featuredImageSrc: require('../../../../assets/images/web3/card2.webp').default,
    logoSrc: require('../../../../assets/images/web3/chainlinklogo.svg').default,
    title:
      'Tintash to natively integrate Chainlink oracles into the Stacks smart contract platform',
    caseStudyDescription:
      'The code & documentation we delivered served as foundational resources for subsequent projects.',
    caseStudyContribution: '',
    furtherdetails: [],
    caseStudyUrl:
      'https://blog.chain.link/tintash-receives-co-sponsored-grant-to-integrate-chainlink-on-stacks/',
    projectName: 'Chainlink',
  },
  {
    id: '3',
    featuredImageSrc: require('../../../../assets/images/web3/card3.webp').default,
    title: 'Tintash helped a trading company make their platform fairer and more sustainable',
    caseStudyDescription:
      'Provided client complete access over their user base to allow off-chain transactions for their users to save transaction fees.',
    caseStudyContribution:
      'Enhanced security of client’s crypto services by creating a sweeping logic.',
    furtherdetails: [],
    caseStudyUrl: '/portfolio/finance-app',
    projectName: '',
  },
  {
    id: '4',
    featuredImageSrc: require('../../../../assets/images/web3/card4.webp').default,
    title: 'The most advanced Bitcoin Wallet for Web 3.0',
    caseStudyDescription:
      'Built a mobile wallet app to allow users to secure, manage and exchange Bitcoin, Stacks, NFTs and other fungible tokens powered by Stacks.',
    furtherdetails: [],
    caseStudyUrl: '',
    projectName: '',
  },
];

const CaseStudySection = () => {
  const caseStudiesUI = selectedCaseStudies.map((caseStudy, index) => (
    <div key={caseStudy.id}>
      <>
        <div
          className="case-study-card case-study-odd"
          style={{
            backgroundImage: `url(${caseStudy.featuredImageSrc})`,
          }}
        >
          <div className="case-study-content col-lg-7 col-md-8 col-xs-12">
            {caseStudy.logoSrc && (
              <img src={caseStudy.logoSrc} className="case-study-logo" alt={caseStudy.id} />
            )}
            <div className="case-study-title-web3 mb-3"> {caseStudy.title} </div>
            <div className="case-study-description mb-3 "> {caseStudy.caseStudyDescription} </div>
            <div className="case-study-description mb-3"> {caseStudy.caseStudyContribution} </div>
            {caseStudy.id !== 'gamerize' ? (
              <div className="case-study-further-container-2 col-12 d-flex align-items-center  m-0 flex-row p-0">
                {caseStudy.furtherdetails.map((card, i) => (
                  <>
                    <div key={card.id} className=" col-lg-5 col-md-6 col-xs-12 pl-0 mb-4 mr-4">
                      <div className=" case-study-details-title p-0">{card.title}</div>

                      <div className="case-study-details-body p-0">{card.content}</div>
                    </div>
                  </>
                ))}
              </div>
            ) : (
              <></>
            )}
            {caseStudy.id === 'gamerize' ? (
              <div className="case-study-gamerize col-12 d-flex align-items-center  m-0 flex-row p-0">
                {caseStudy.furtherdetails.map((card, i) => (
                  <>
                    <div key={card.id} className="col-lg-4 col-md-6 col-xs-12 pl-0 mb-4 mr-4">
                      <div className=" case-study-details-title p-0">{card.title}</div>

                      <div className="case-study-details-body p-0">{card.content}</div>
                    </div>
                  </>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
          {caseStudy.caseStudyUrl !== '' ? (
            <Link
              onClick={() =>
                Event(
                  `${caseStudy.projectName} Case Study`,
                  `${caseStudy.projectName} Case Study Button is clicked`,
                  'Homepage'
                )
              }
              to={caseStudy.caseStudyUrl}
            >
              <span className="case-study-link mr-2">
                {caseStudy.projectName === 'Chainlink' ? 'Read More' : 'View case study'}
              </span>
              <svg
                width="15"
                height="15"
                viewBox="0 0 21 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                color="inherit"
              >
                <path
                  d="M1.11358 5.34386L18.332 5.30243L14.6914 2.04963C14.446 1.82173 14.4256 1.4488 14.6505 1.20017C14.8755 0.951553 15.2437 0.930835 15.4891 1.15874L20.2955 5.50961C20.4182 5.6132 20.5 5.77895 20.5 5.9447C20.5 6.11045 20.4386 6.27619 20.3159 6.37979L15.5096 10.8343C15.3868 10.9378 15.2437 11 15.1005 11C14.9573 11 14.7937 10.9171 14.671 10.7928C14.446 10.5442 14.4665 10.1713 14.7119 9.94336L18.4343 6.5041L1.09313 6.54553C0.765885 6.54553 0.5 6.27619 0.5 5.9447C0.5 5.6132 0.765885 5.34386 1.11358 5.34386Z"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="0.3"
                />
              </svg>
            </Link>
          ) : (
            <></>
          )}
        </div>
      </>
    </div>
  ));

  return (
    <section className="container-fluid main-padding selected-case-studies py-5">
      <div className="homepage-heading">Selected case studies</div>

      <div className="case-studies-container">{caseStudiesUI}</div>
    </section>
  );
};

export default CaseStudySection;
