// extracted by mini-css-extract-plugin
export var arrow = "UpdatesNewsCard-module--arrow--Afjzv";
export var body = "UpdatesNewsCard-module--body--PdqiY";
export var card = "UpdatesNewsCard-module--card--zk14P";
export var featuredImage = "UpdatesNewsCard-module--featured-image--rvCPo";
export var header = "UpdatesNewsCard-module--header--ylpIF";
export var logo = "UpdatesNewsCard-module--logo--IUwIQ";
export var logoContainer = "UpdatesNewsCard-module--logo-container--CSNj9";
export var mainContainer = "UpdatesNewsCard-module--mainContainer--LurPP";
export var rectLogo = "UpdatesNewsCard-module--rect-logo--pj0aj";
export var roundLogo = "UpdatesNewsCard-module--round-logo--99lwW";
export var title = "UpdatesNewsCard-module--title--3Ys8m";