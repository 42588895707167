import React from 'react';
import '../../../assets/css/connect.css';
import '../../../assets/css/live-ops.css';
import HeadingSection from './Section/HeadingSection';
import ClientsLogoSection from './Section/ClientsLogoSection';
import ProcessSection from './Section/ProcessSection';
import CaseStudySection from './Section/CaseStudySection';
import ClientsStatsSection from './Section/ClientsStatsSection';
import UpdateNewsSection from '../../Shared/Section/UpdatesNewsSection/UpdatesNewsSection';
import {Helmet} from 'react-helmet';
import StudioNavbar from '../../Shared/StudioNavbar';

function Web3Template() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Web3 | Tintash</title>
      </Helmet>
      <StudioNavbar studioName="Web3.0 Studio" />
      <HeadingSection />
      <ClientsStatsSection />
      <ClientsLogoSection />
      <CaseStudySection />
      <UpdateNewsSection />
      <ProcessSection />
    </>
  );
}

export default Web3Template;
