import React from 'react';
import * as classes from './UpdatesNewsSection.module.css';
import 'react-multi-carousel/lib/styles.css';
import stacksLogo from '../../../../assets/images/blog-posts/client-logos/stacks.png';
import chainlinkLogo from '../../../../assets/images/blog-posts/client-logos/chainlink.png';
import tintashLogo from '../../../../assets/images/blog-posts/tintashLogo.png';
import polkacityLogo from '../../../../assets/images/blog-posts/client-logos/polka.png';
import UpdatesNewsCard from './UpdatesNewsCard/UpdatesNewsCard';

export default function Component() {
  return (
    <section className={classes.newsSection} id="thinking">
      <div className={classes.container}>
        <header className={classes.header}>
          <h2 className={classes.subtitle}>LATEST FROM OUR TEAM AND CLIENTS</h2>
          <h1 className={classes.title}>Thinking & Mentions</h1>
        </header>
        <div className={classes.cards}>
          <UpdatesNewsCard
            companyName="Stacks"
            title="The Stacks Blockchain API Now Supports Coinbase's Rosetta Standard"
            featuredImageLoading="lazy"
            featuredImageSrc="stacks.png"
            link="https://blog.blockstack.org/the-stacks-blockchain-api-now-supports-coinbases-rosetta-standard/"
            logoSrc={stacksLogo}
          />
          <UpdatesNewsCard
            companyName="Chainlink"
            title="Tintash Receives Co-Sponsored Grant to Natively Integrate Chainlink on Stacks"
            featuredImageLoading="lazy"
            featuredImageSrc="chainlink.png"
            link="https://blog.chain.link/tintash-receives-co-sponsored-grant-to-integrate-chainlink-on-stacks/"
            logoSrc={chainlinkLogo}
          />
          <UpdatesNewsCard
            companyName="Tintash Blog"
            title="Decoding Worldcoin: A Technical Exploration of Sam Altman’s Blockchain Venture"
            featuredImageLoading="lazy"
            featuredImageSrc="worldCoin.webp"
            link="https://medium.com/tintash/decoding-worldcoin-a-technical-exploration-of-sam-altmans-blockchain-venture-b482ab099ed4"
            logoSrc={tintashLogo}
          />
          <UpdatesNewsCard
            companyName="Polkacity"
            title="Tintash partners with Polkacity to create a NFT Game design"
            featuredImageLoading="lazy"
            featuredImageSrc="polkacity.png"
            link="https://polkacity.medium.com/polkacity-nft-gaming-design-32c8c86deb19/"
            logoSrc={polkacityLogo}
          />
        </div>
      </div>
    </section>
  );
}
